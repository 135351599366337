// Sections
import Hero from "../../sections/About/Hero/Hero"
import Team from "../../sections/About/Team/Team"
import Goals from "../../sections/About/Goals/Goals"
import Articles from "../../sections/Home/Articles/Articles"

const About = () => {
	return (
		<div className="sections">
			<Hero />
			<Team />
			<Goals />
			<Articles />
		</div>
	)
}

export default About