import Hero from "../../sections/Collections/Hero/Hero"
import CollectionItems from "../../sections/Collections/СollectionItems/CollectionItems"
import Collaborations from "../../sections/Home/Collaborations/Collaborations"
import Articles from "../../sections/Home/Articles/Articles"

const Collections = () => {
	return (
		<div className="sections">
			<Hero />
			<CollectionItems />
			<Collaborations />
			<Articles />
		</div>
	)
}

export default Collections