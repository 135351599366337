import Skeleton from "react-loading-skeleton"
import './CollectionSkeleton.scss'
import 'react-loading-skeleton/dist/skeleton.css'

const CollectionSkeleton = () => (
	<div className="collection-skeleton">
		<div className="collection-heading">
			<div className="collection-heading-info">
				<Skeleton />
				<Skeleton />
			</div>
			<div className="collection-heading-img">
				<Skeleton />
			</div>
		</div>

		<div className="collection-info">
			<div className="collection-info-inner">
				<div className="collection-info-items">
					<div className="collection-info-items-left">
						{[...Array(3)].map((_, index) => (
							<div key={index} className="collection-info-item">
								<Skeleton />
								<Skeleton />
							</div>
						))}
					</div>
					<div className="collection-info-items-right">
						{[...Array(3)].map((_, index) => (
							<div key={index} className="collection-info-item">
								<Skeleton />
								<Skeleton />
							</div>
						))}
					</div>
				</div>
				<Skeleton />
			</div>
		</div>

		<div className="collection-graph">
			<Skeleton />
		</div>

		<div className="collection-tokens">
			{[...Array(6)].map((_, index) => (
				<Skeleton
					key={index}
				/>
			))}
		</div>
	</div>
)

export default CollectionSkeleton