import './Team.scss'
import H2box from '../../../components/H2box/H2box'
import TeamCard from '../../../components/sections/TeamCard/TeamCard'

const Team = () => {
	return (
		<section className='team'>
			<div className="container">
				<div className="team-wrapper">
					<H2box h2='Our Team' subtitle="We don't just work together - we inspire each other by sharing ideas and experience." margin='sm' />
					<div className="team-cards">
						<TeamCard  title='Art  Creator'
						text='The designer is responsible for transforming concepts into finished products by meticulously refining each element and creating a cohesive image. All visual solutions we see in the end reflect a high level of professionalism and a creative approach.'
						twitterSrc='https://x.com/d_art90' />
						<TeamCard  title='Founder'
						text='Founder — a key figure responsible for organizing new collaborations and selecting names for collections. He plans and conducts giveaways, creating an integrated working system, and shares important information with the community. Cooperation between the designer and web developer is executed with ease and efficiency.'
						twitterSrc='https://x.com/crypmatcha'  />
						<TeamCard  title='Developer'
						text='The web developer plays a key role in distributing revenues among all holders. He is responsible for developing smart contracts and managing the backend and frontend, ensuring the efficient execution of the program code.'
						twitterSrc='https://x.com/bug_d3v' />
					</div>
				</div>
			</div>
		</section>
	)
}

export default Team