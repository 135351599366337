import { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { gql } from '@apollo/client'
import client from '../../../../global/ApolloClient'
import Spinner from '../../spinners/Spinner'
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'

ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend, Filler)

interface CoinAmount {
  amount: string
}

interface TimeSeriesData {
  coinAmount: CoinAmount
  timestamp: string
}

interface GraphProps {
  collectionAddr: string
}

const Graph = ({ collectionAddr }: GraphProps) => {
  const [data, setData] = useState<{ amount: number; timestamp: string }[]>([])
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const fetchGraphData = async () => {
    setLoading(true)
    try {
      const { data: response } = await client.query<{ collectionFloorPriceTimeSeries: TimeSeriesData[] }>({
        query: gql`
          query Query($address: String!, $timeSeriesSelection: TimeSeriesSelectionInput) {
            collectionFloorPriceTimeSeries(
              address: $address,
              timeSeriesSelection: $timeSeriesSelection
            ) {
              coinAmount {
                amount
              }
              timestamp
            }
          }
        `,
        variables: {
          address: collectionAddr,
          timeSeriesSelection: {
            granularity: 'MONTH',
          },
        },
        fetchPolicy: 'cache-first',
      })

      if (Array.isArray(response.collectionFloorPriceTimeSeries) && response.collectionFloorPriceTimeSeries.length > 0) {
        const formattedData = response.collectionFloorPriceTimeSeries.map(item => {
          const amount = parseFloat(item.coinAmount.amount) / 1000000
          const timestamp = item.timestamp

          return {
            amount: isNaN(amount) ? 0 : amount,
            timestamp: timestamp,
          }
        })
        setData(formattedData)
        setError(null)
      } else {
        setError('Received data has an invalid format or is empty.')
      }
    } catch (err) {
      console.error('Error fetching graph data:', err)
      setError('Error fetching data for the graph.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchGraphData()
  }, [collectionAddr])

  const chartData = {
    labels: data.map(item => new Date(item.timestamp).toLocaleDateString()),
    datasets: [
      {
        label: 'Floor Price',
        data: data.map(item => item.amount),
        fill: true,
        backgroundColor: 'rgba(74, 150, 91, 0.2)',
        borderColor: '#4A965B',
        tension: 1,
        borderWidth: 2,
      },
    ],
  }

  const options: any = {
    responsive: true,
	
    plugins: {
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
      },
	  legend: {
		display: false,
	  },
    },
    animation: {
      duration: 300,
      easing: 'linear',
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        beginAtZero: false,
      },
    },
  }

  return (
    <div className="graph">
      {loading ? (
        <Spinner size='md' />
      ) : error ? (
        <div className="graph-error">{error}</div>
      ) : data.length > 0 ? (
        <Line data={chartData} options={options} />
      ) : (
        <div>No data available for the selected collection.</div>
      )}
    </div>
  )
}

export default Graph