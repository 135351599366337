import Heading from '../../../components/Heading/Heading'

const Hero = () => {
  return (
	<section className='hero'>
		<div className="container">
			<Heading type='center' h1='Collections' subtitle='Latest NFT works' />
		</div>
	</section>
)
}

export default Hero