import './Hero.scss'
import LinkRouter from '../../../components/links/LinkRouter/LinkRouter'
import Heading from '../../../components/Heading/Heading'
import matcha from '../../../../assets/img/svg/matcha.svg'

const Hero = () => {
	const heroProps = {
		type: 'horizontal',
		h1: "CRYPTOMATCHA",
		subtitle: "Explore, create, own — together with us in the world of crypto and NFTs",
	}

	return (
		<section className="hero">
			<div className="container">
				<div className="hero-wrapper">
					<div className="hero-info">
						<Heading {...heroProps } />
						<div className="hero-links">
							<LinkRouter type='green' src='/apps' text='View Apps' />			
							<LinkRouter type='gradient' src='/about' text='Learn More' />			
						</div>
					</div>
					<div className="hero-img">
						<img src={matcha} alt="Cryptomatcha" loading="eager" />
					</div>
				</div>
			</div>
		</section>
	)
}

export default Hero
