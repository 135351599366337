import "./Info.scss"
import H2box from "../../../components/H2box/H2box"
import InfoCard from "../../../components/sections/InfoCard/InfoCard"
import i1 from "../../../../assets/img/sections/short-info/i1.svg"
import i2 from "../../../../assets/img/sections/short-info/i2.svg"
import i3 from "../../../../assets/img/sections/short-info/i3.svg"

const Info = () => {
	const H2props = {
		h2: "Reach new horizons",
		subtitle: "on Stargaze with us"
	}

	return (
		<section className="short-info">
			<div className="container">
				<div className="short-info-wrapper">
					<H2box {...H2props} />
					<div className="short-info-cards">
						<InfoCard type="" glowId="g1" glowFill="#38EC8B" iconSrc={i1} title="Building Apps"
							text="We're developing apps integrated with the Stargaze blockchain to unlock the full potential of NFTs."
						/>
						<InfoCard type="" glowId="g2" glowFill="#DB2777" iconSrc={i2} title="Stargaze"
						text="A decentralized ecosystem for creating, trading, and owning unique digital assets."
						linkSrc="https://www.stargaze.zone/" linkText="Go to Stargaze" />
						<InfoCard type="" glowId="g3" glowFill="#9BF6E1" iconSrc={i3} title="NFT"
						text="We're crafting exclusive NFT collections on Stargaze, pushing the boundaries of digital art and innovation."
						 />
					</div>
				</div>
			</div>
		</section>
	)
}

export default Info