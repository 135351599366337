import Heading from "../../../components/Heading/Heading"

const Hero = () => {
  return (
		<section className="hero">
			<div className="container">
				<Heading type="center" h1="About Us" subtitle="In these times, when the world of digital assets and technology is  evolving at a surprisingly fast pace, young and ambitious teams play a  key role in shaping this trend." />
			</div>
		</section>
	)
}

export default Hero